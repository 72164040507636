import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FormControl } from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import BootstrapTable from 'react-bootstrap-table-next';
import Form from "react-bootstrap/Form";
import pagination from './pagination';
import SearchBox from './SearchBox';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import './table.css';
import { useContext } from "react";
import { ModalPopContext } from "../../Technician/ModalPopContext";
import { deleteTechnicianOffTime } from "../../Api/technician";
import { addTechnicianOffTime } from "../../Api/technician";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import dayjs from "dayjs";
import { API_URL } from "../../Api/config.js";




const Informasi = () => {

    const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear, technicianofftimerequest, setTechnicianofftimerequest] = useContext(ModalPopContext);

    const [addBreakRequest, setAddBreakRequest] = useState('');


    const data = technicianOfftime && technicianOfftime.map((item) => {
        const start_date = item.start_date_time.split(" ");
        const end_date = item.end_date_time.split(" ");

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const start_date_split = start_date[0].split("-");
        const end_date_split = end_date[0].split("-");
        const formatted_start_date = monthNames[start_date_split[1] - 1] + "-" + start_date_split[2] + "-" + start_date_split[0];
        const formatted_end_date = monthNames[end_date_split[1] - 1] + "-" + end_date_split[2] + "-" + end_date_split[0];

        // Convert time to 12-hour format
        const formatTime = (time) => {
            const [hour, minute] = time.split(":");
            return `${hour % 12 || 12}:${minute} ${hour >= 12 ? 'PM' : 'AM'}`;
        };

        return {
            id: item.id,
            startdate: start_date[0], // Original date for sorting
            formatted_startdate: formatted_start_date, // Formatted date for display
            starttime: formatTime(start_date[1]),
            enddate: end_date[0], // Original date for sorting
            formatted_enddate: formatted_end_date, // Formatted date for display
            endtime: formatTime(end_date[1]),
            off_time_note: item.off_time_note,
            status: "APPROVED",
        }
    })


    const today = new Date();
    const tomorrow = new Date();

    // // Set start time to 7 PM for today
    const startHours = 7;
    const startime = 0;
    const startsecondTime = 0;
    const startmicroseconds = 0;

    const endhours = 19;
    const endMinutes = 0;
    const endSeconds = 0;
    const endmicrominute = 0;

    today.setHours(startHours, startime, startsecondTime, startmicroseconds); // 19:00 = 7 PM
    // Set end time to 7 PM for tomorrow
    // tomorrow.setDate(tomorrow.getDate() + 1);
    // tomorrow.setDate(tomorrow.getDate());
    // tomorrow.setHours(19, 0, 0, 0); // 19:00 = 7 PM 
    tomorrow.setHours(endhours, endMinutes, endSeconds, endmicrominute);


    const [values, setValues] = useState([
        [today, tomorrow] // Define the start and end time for the default range
    ]);


    // const [values, setValues] = useState([today, tomorrow]);

    // Initialize state with a range (start and end times)


    // const handleDateChange = (selectedValues) => { 
    //     const adjustedValues = selectedValues.map(([start, end]) => {
    //         const startDate = new Date(start);
    //         let endDate = new Date(end);

    //         // If end date's time is in AM, adjust it to PM
    //         if (endDate.getHours() < 12) {
    //             endDate.setHours(19); // Set default time to 7:00 PM
    //         }

    //         // Return updated range
    //         return [startDate.getTime(), endDate.getTime()];
    //     });

    //     setValues(adjustedValues); // Update state
    // };

    // const handleDateChange = (selectedValues) => {
    //     const adjustedValues = selectedValues.map(([start, end]) => {
    //         const startDate = new Date(start);
    //         let endDate = new Date(end);

    //         // If the selected end time is the default (early morning, like 7:00 AM), set it to 7:00 PM
    //         if (endDate.getHours() === 7 && endDate.getMinutes() === 0) {
    //             endDate.setHours(19, 0, 0, 0); // Set to 7:00 PM
    //         }

    //         // If the selected end time is exactly 11:00 AM, keep it as 11:00 AM
    //         else if (endDate.getHours() === 11 && endDate.getMinutes() === 0) {
    //             endDate.setHours(11, 0, 0, 0); // Ensure it stays 11 AM
    //         }

    //         return [startDate.getTime(), endDate.getTime()];
    //     });

    //     setValues(adjustedValues); // Update state
    // };

    const handleDateChange = (selectedRanges) => {
        const adjustedValues = selectedRanges.map(([start, end]) => {
            let startDate = new Date(start);
            let endDate = new Date(end);

            if (startDate.getHours() !== startHours || startDate.getMinutes() !== startime) {
                startDate.setHours(startHours, startime, startsecondTime, startmicroseconds);
            }

            if (endDate.getHours() === 7 && endDate.getMinutes() === 0) {
                endDate.setHours(endhours, endMinutes, endSeconds, endmicrominute);
            } else if (endDate.getHours() === 11 && endDate.getMinutes() === 0) {
                endDate.setHours(11, 0, 0, 0);
            }

            return [startDate, endDate]; // Ensure returning Date objects
        });

        setValues(adjustedValues);
    };


    // const handleDateChange = (selectedValues) => {
    //     const adjustedValues = selectedValues.map(([start, end]) => {
    //         const startDate = new Date(start);
    //         let endDate = new Date(end);
    //         console.log("aminesh verma", startDate.getHours());
    //         console.log("aminesh end", endDate.getHours());
    //     });

    //     // if any date of selectedValues are new and not in selectedValues then set default time to 7 am to 7 PM

    //    // setDateArray(selectedValues);
    // };


    const formattedValues = values.map((range) =>
        Array.isArray(range)
            ? range.map((date) => dayjs(date).format("YYYY/MM/DD HH:mm"))  // Format range dates
            : dayjs(range).format("YYYY/MM/DD HH:mm")                      // Format single date
    );

    useEffect(() => {
    }, [technicianOfftime]);

    const addBreakTechnician = () => {

        if (formattedValues.some(range => range.length !== 2)) {
            NotificationManager.error("Each selected range must have a start and end time");
            return;
        }
        if (addBreakRequest === "") {
            NotificationManager.error("Please select off time request");
            return;
        }

        const formdata = new FormData();
        formdata.append("technician_id", technicianDetails.id);
        formdata.append("off_time_note", addBreakRequest);
        formdata.append("off_time_dates", JSON.stringify(formattedValues));

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        fetch(API_URL + "addTechnicianOffTime", requestOptions)
            .then((response) => response.json()) // Parse JSON response
            .then((result) => {
                if (result.status === "success") {
                    const newTechnicianOffTime = result.data;
                    setTechnicianOfftime(newTechnicianOffTime); // Assuming this updates the UI with new off-time data
                    setAddBreakRequest(""); // Reset the request field
                    NotificationManager.success("Off time added successfully");
                } else {
                    NotificationManager.error("Something went wrong while adding off time");
                }
            })
            .catch((error) => {
                console.error(error);
                NotificationManager.error("An error occurred while adding off time");
            });
    }

    const [selected, setSelected] = useState([]);

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Delete-Active-bttn me-2" onClick={
                    () => {
                        const deleteId = row.id;
                        handleDelete(deleteId)
                    }
                }><RiDeleteBin6Line /></Button>
            </div>
        );
    }

    const handleDelete = (deleteId) => {
        alert("Are you sure you want to delete this record?");
        const formData = new FormData();
        formData.append("off_time_id", deleteId);
        formData.append("technician_id", technicianDetails.id);
        deleteTechnicianOffTime(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == "1") {
                    const newTechnicianOfftime = technicianOfftime.filter((item) => item.id !== deleteId);
                    setTechnicianOfftime(newTechnicianOfftime);
                }
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }


    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true
    }, {
        dataField: 'startdate',
        text: 'START DATE',
        sort: true,
        formatter: (cell, row) => row.formatted_startdate,
        sortValue: (cell, row) => row.startdate
    }, {
        dataField: 'starttime',
        text: 'START TIME',
        sort: true
    },
    {
        dataField: 'enddate',
        text: 'END DATE',
        sort: true,
        formatter: (cell, row) => row.formatted_enddate,
        sortValue: (cell, row) => row.enddate
    },
    {
        dataField: 'endtime',
        text: 'END TIME',
        sort: true
    },
    {
        dataField: 'off_time_note',
        text: 'OFF TIME REQUEST',
        sort: true
    }, {
        dataField: 'status',
        text: 'STATUS',
        sort: true
    },
    {
        text: 'ACTION',
        isDummy: true,
        formatter: optionFormatter
    }];

    const [state, setState] = useState({
        start: new Date(),
        end: new Date(),
        focusedInput: null,
        timezone: "America/Los_Angeles",
        secondDisplay: false
    });

    const handleSearch = (e) => {
        console.log(e.target);
    }

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));


    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const [timezone, setTimezone] = useState("America/Los_Angeles");
    const [secondDisplay, setSecondDisplay] = useState(false);

    const applyCallback = (startDate, endDate) => {
        console.log("Apply Callback");
        console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
        console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));


        setStart(startDate);
        setEnd(endDate);

        const start_date = startDate.format("YYYY-MM-DD HH:mm:ss");
        const end_date = endDate.format("YYYY-MM-DD HH:mm:ss");

        console.log("start_date", start_date);
        console.log("end_date", end_date);

        setStartTime(start_date);
        setEndTime(end_date);



    };

    const rangeCallback = (index, value) => {
        console.log(index, value);
    };

    const onClick = () => {
        const newStart = moment(start).subtract(3, "days");
        setStart(newStart);
    };

    const renderVanillaPicker = (ranges, local, maxDate) => {
        const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
        const disabled = false;



        return (
            <div>
                {/* <br /> */}
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={applyCallback}
                    rangeCallback={rangeCallback}
                    smartMode
                >
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        style={{ cursor: "pointer" }}
                        disabled={disabled}
                        value={value}
                    />
                </DateTimeRangeContainer>
                {/* <br /> */}
            </div>
        );
    };

    let now = new Date();

    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1st August 18": [
            moment("2018-08-01 00:00:00"),
            moment("2018-08-02 23:59:59")
        ],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };

    let local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };

    let maxDate = moment(end).add(24, "hour");

    let pickersRender = (
        <div>
            {/* <br /> */}
            {renderVanillaPicker(ranges, local, maxDate)}
        </div>
    );



    return (
        <>

            <div className="card-custom-header">
                <h5 className="tab-content-title mb-3">Add Your Off Times</h5>
            </div>
            <div className="row add-offtime-row mb-4">
                <div className="col-md-4">
                    <Form.Group
                        className="booking-custom-lable"
                        controlId="formBasicEmail"
                    >
                        <div className="row">
                            <div className="col-md-12">
                                {/* {pickersRender} */}
                                <DatePicker
                                    multiple
                                    value={values}
                                    onChange={setValues}
                                    // onChange={(selectedRanges) =>
                                    //     handleDateChange(
                                    //         selectedRanges.map((range) =>
                                    //             range.map((date) => (date instanceof Date ? date.getTime() : date)) // Convert to timestamps
                                    //         )
                                    //     )
                                    // }
                                    // format="YYYY/MM/DD HH:mm"  // Display both date and time 
                                    format="YYYY/MM/DD hh:mm A"
                                    range
                                    // minDate={new Date()}  
                                    minTime="07:00"
                                    maxTime="19:00"
                                    plugins={[
                                        <DatePanel />,
                                        <TimePicker key="timePicker" position="bottom" />
                                    ]}
                                />
                            </div>
                        </div>


                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <div className="off-time-detail-btn">

                        <Form.Select aria-label="Default select example" value={addBreakRequest} onChange={
                            (e) => {
                                console.log(e.target.value);
                                setAddBreakRequest(e.target.value);
                            }
                        } >
                            <option value="">Select Off Time Request</option>
                            <option value="Sick Time">Sick Time</option>
                            <option value="Unrequested Absence">Unrequested Absence</option>
                            <option value="Vacation Time">Vacation Time</option>
                            <option value="Unpaid Time Off">Unpaid Time Off</option>
                        </Form.Select>


                    </div>
                </div>
                <div className="col-md-4">
                    <div className="off-time-detail-btn">
                        <button
                            type="button"
                            className="btn bg-green-dark action-btn btn-green"
                            onClick={addBreakTechnician}
                        >
                            Add Break
                        </button>
                    </div>
                </div>
            </div>

            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <SearchBox onSearch={handleSearch} />
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={data} columns={columns} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <NotificationContainer />

        </>
    );



}

export default Informasi;




















