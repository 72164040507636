import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import BootstrapTable from 'react-bootstrap-table-next';
import pagination from './pagination';
import "./Stax.css";
import { Roller } from "react-awesome-spinners";
import { getmissmatchkeapdata } from "../Api/crm";
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";



function Getmismatchkeapid() {
    const [loading, setLoading] = useState(false);
    const [keapdata, setKeapdata] = useState([]);

    const getkeapdata = () => {
        setLoading(true);

        // Call the API to fetch data
        getmissmatchkeapdata()
            .then((response) => {
                if (response.code === 200) {
                    const apiResponse = response.data;

                    // Check if the API status is success
                    if (apiResponse.status === 1) {
                        const fetchedMargedata = apiResponse.data;

                        // Update the state with the processed data
                        setKeapdata(fetchedMargedata);

                        // Log the processed data for verification
                        console.log("Processed Data:", fetchedMargedata);
                    } else {
                        // Handle error when status is not 1
                        console.error("API returned non-success status:", apiResponse);
                        alert("Failed to fetch data, please try again later.");
                    }
                } else {
                    // Handle invalid response code (not 200)
                    console.error("Invalid response code:", response.code);
                }
            })
            .catch(function (err) {
                // Handle network or unexpected errors
                console.error("Error fetching data:", err);
                alert("An error occurred while fetching data. Please check your connection or try again later.");
            })
            .finally(() => {
                // Hide the loading spinner after the request completes
                setLoading(false);
            });
    };

    const [currentsatxuserid, setCurrentsatxuserid] = useState(0);


    useEffect(() => {
        getkeapdata();
    }, []);



    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Stax data</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <div className="animated fadeIn">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex justify-content-between table-card-header" >
                                                                                <div className="card-header d-flex table-card-header justify-content-between align-items-center">
                                                                                    {/* <div className="ml-auto d-flex">

                                                                                        <Button
                                                                                            onClick={startstaxsync}
                                                                                            className="btn btn-sm float-right add-user-btn"
                                                                                        >
                                                                                            Get stax id`s`
                                                                                        </Button>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">

                                                                                <Table striped bordered hover>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>Name</th>
                                                                                            <th>Email</th>
                                                                                            <th>KEAP ID</th>
                                                                                            <th>DOES KEAP ID EXIST IN KEAP</th>
                                                                                            <th>DOES EMAIL MATCH</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {keapdata && keapdata.map((item, index) => {
                                                                                            const currentUserIndex = index + 1; // Adjust index to match your ID logic
                                                                                            return (
                                                                                                <React.Fragment key={currentUserIndex}>
                                                                                                    {currentUserIndex === currentsatxuserid && currentUserIndex < keapdata.length ? (
                                                                                                        <tr>
                                                                                                            <td colSpan={6} style={{ textAlign: 'center' }}>
                                                                                                                <Spinner
                                                                                                                    as="span"
                                                                                                                    animation="border"
                                                                                                                    size="sm"
                                                                                                                    role="status"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ) : (
                                                                                                        <tr id={`user_id_${currentUserIndex}`}>
                                                                                                            <td>{currentUserIndex}</td>
                                                                                                            <td>{item.full_name}</td>
                                                                                                            <td>{item.user_email}</td>
                                                                                                            <td>{item.keap_id}</td>
                                                                                                            <td>{item.DOES_KEAP_ID_EXIST}</td>
                                                                                                            <td>{item.DOES_EMAIL_MATCH}</td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}

                                                                                    </tbody>
                                                                                </Table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Getmismatchkeapid;